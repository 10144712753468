@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100;200;300;400;500;600;700;800;900&display=swap');

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Outfit', sans-serif;

}

body {
  background: #f2f2f2;
}

.active {
  background: #0928;
  color: #fff;
}

h1,
h4,
p,
a {

  text-decoration: none;
}

ul {
  list-style-type: none;
}

.btn {
  padding: 10px 32px;
  font-size: 1rem;
  text-transform: uppercase;
  background: rgb(248, 217, 15);
  color: #222;
  border: 1px solid #fff;
  font-weight: 600;
  cursor: pointer;
}

.btn-light {
  background: transparent;
  color: #fff;
}

.btn:hover {
  color: #fff;
  transition: 0.3s;
  background: rgba(255, 255, 255, 0.2);
}

.hero-img {

  width: 100%;
  background: rgba(0, 0, 0, 0.5);
  height: 60vh;
  position: relative;
}

.heading {

  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.hero-img::before {
  content: "";
  background: url("https://images.unsplash.com/photo-1467232004584-a241de8bcf5d?crop=entropy&cs=tinysrgb&fm=jpg&ixlib=rb-1.2.1&q=80&raw_url=true&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1169");
  background-position: center;
  background-size: cover top;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}

.bottom {
  position: absolute;
  bottom: 10px;
  text-align: center;
  width: 100%;
}

.hero-img h1 {
  font-size: 2.4rem;
}

.hero-img p {
  font-size: 1.4rem;
  text-align: center;
}

.mask {
  width: 100%;
  height: 100vh;
  position: relative;
}

.into-img {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  object-fit: cover;
}

.mask::after {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #837f7f78;
  opacity: 0.1;
}

.hero {
  height: 100%;
  width: 100%;
}

.hero .content {
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  text-align: center;
}

.hero .content h1 {
  font-size: 4rem;
  padding: 0.6rem 0 1.5rem;
}

.hero .content p {
  font-size: 1.4rem;
  font-weight: 200;
  text-transform: uppercase;
}

.content .btn {
  margin: 1rem 0.2rem;
}

video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* index Css Start */
.pb90 {
  padding-bottom: 90px !important;
}

section.pt120 {
  padding: 120px 0;
  position: relative;
}

.iconbox-style1 {
  background-color: #fff;
  border-radius: 12px;
  margin-bottom: 30px;
  padding: 30px;
  position: relative;
  transition: all .4s ease;
}

.iconbox-style1 .icon,
.iconbox-style1 .text,
.iconbox-style1 .title {
  transition: all .4s ease;
}

.iconbox-style1 .icon {
  background-color: #dbd9d9;
  border-radius: 50%;
  color: var(--headings-color);
  display: block;
  font-size: 30px;
  height: 70px;
  line-height: 75px;
  margin-bottom: 65px;
  text-align: center;
  width: 70px;
  transition: all .4s ease;
  color: #222;
}

.iconbox-content .title {
  color: #181a20;
}

.text {
  color: #181a20;
  font-family: var(--title-font-family);
  font-weight: 400;

}

.item {
  margin-right: 20px;
}

.iconbox-style2 {
  background-color: #fff;
  border-radius: 12px;
  margin-bottom: 30px;
  padding: 60px 35px 20px;
  position: relative;
  transition: all .4s ease;
}

.iconbox-style2 .icon {
  margin-bottom: 25px;
}

.iconbox-style2 .title {
  margin-bottom: 12px;
}

.iconbox-style2 .text {
  color: #181a20;
  font-weight: 400;
  margin-bottom: 25px;
}

.iconbox-style2:hover {
  -o-box-shadow: 0 10px 40px rgba(24, 26, 32, .05);
  box-shadow: 0 10px 40px rgba(24, 26, 32, .05);
}

a.taxonomy-link.hover-effect-flat img {
  width: 100%;
  height: 250px;
  border-radius: 7px;
  background-size: cover;
  object-fit: cover;
}

.taxonomy-item {
  margin-bottom: 30px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
}

.hover-effect-flat:before {
  opacity: .3;
  background-color: #000;
}

.hover-effect-flat:before,
.hover-effect:before {
  content: "";
}

.hover-effect-flat {
  border-radius: 3px;
  cursor: pointer;
  overflow: hidden;

}

.taxonomy-text-wrap {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.taxonomy-item:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #0e0e0e;
  opacity: 0.4;
}

.taxonomy-item:hover img {
  opacity: 0.6;
}

.taxonomy-title {

  font-size: 20px;
  font-weight: 500;
  color: #fff;
}

.taxonomy-subtitle {
  font-size: 14px;
  text-transform: uppercase;
  color: #fff;
}

/* .react-multi-carousel-item{
  margin-right: 45px;
} */
.section-areas img {
  width: 240px;
  height: 200px;
  object-fit: cover;
  border-radius: 8px;
}

.area-box {
  margin-top: 14px;
}

.area-box h6 {
  font-size: 17px;
  font-weight: 500;
}

.gugaon-box {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 25px;
  border-radius: 10px;
  overflow: hidden;
  height: 280px;
}

.gugaon-box img {
  width: 100%;
  height: 100%;
  background-size: auto;
}

.gugaon-box:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  /* width: 20em;
  height: 20em; */
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.48) 0%, rgba(0, 0, 0, 0.48) 100%);
}

.gurgaonTitle {
  font-size: 2em;
  color: white;
  text-shadow: 0.1em 0.1em 0.5em rgba(0, 0, 0, 0.5);
  font-weight: 600;
  text-align: center;
}

.gurgaon-info {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
}







/* index Css end */
.pagination-button button:disabled,
button[disabled] {
  border: none;
  background-color: #cccccc;
  color: #666666;
  /* width: 70px; */
  /* padding: 10px 10px; */
  margin-right: 10px;
}

.pagination-button button {
  color: #fff;
  outline: none;
  border: none;
  padding: 10px 32px;
  font-size: 1rem;
  text-transform: uppercase;
  color: #222;
  border: 1px solid #fff;
  font-weight: 600;
  cursor: pointer;
  margin-right: 10px;
  border-radius: 8px;
}

@media screen and (max-width:640px) {
  .hero .content h1 {
    font-size: 3rem;
  }

  .hero .content p {
    font-size: 1.4rem;
  }
}

@media screen and (max-width: 640px) {

  .hero-img h1 {
    font-size: 2.4rem;
  }

  .hidetextmobile {
    display: none;
  }


}
@media screen and (max-width: 940px) {

  .hero-img h1 {
    font-size: 2.4rem;
  }

  .hidetextmobile {
    display: none;
  }


}