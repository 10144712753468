/* .rounded-img.project-section {
    display: flex;
    flex-wrap: wrap;
} */
.box_cat_rooms {
    display: block;
    margin-bottom: 25px;
    height: 500px;
    position: relative;
    overflow: hidden;
    background-color: #ccc;
    border-radius: 10px;
    -webkit-box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.2);
    box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.2);
}
.brand-card {
    height: 60px;
    width: 40%;
    background-color: #fff;
    position: absolute;
    z-index: 9;
    right: 0;
    top: 0px;
    border-radius: 10px 0px 5px 35px;
}
.brand-card img {
    width: 120px;
    height: 50px;
}
.box_cat_rooms figure {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 100%;
}

.background-image {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    -o-transition: .5s;
    -ms-transition: .5s;
    -moz-transition: .5s;
    -webkit-transition: .5s;
    transition: all .5s ease;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
}
.box_cat_rooms .info {
    position: unset;
    width: 100%;
    z-index: 9;
    display: block;
    padding: 25% 30px 30px;
    color: #fff;
    left: 0;
    text-align: center;
    backface-visibility: hidden;
    -webkit-transform: translate(0, 0);
    -moz-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 90px);
    -moz-transition: 0.4s;
    -o-transition: 0.4s;
    -webkit-transition: 0.4s;
    -ms-transition: 0.4s;
    transition: 0.4s;
    background: 0 0;
    background: -webkit-linear-gradient(top, transparent, #000);
    background: linear-gradient(to bottom, transparent, #000);
    margin-top: auto;
    height: 100%;
    display: flex;
    flex-direction: column;
    /* justify-content: end; */
}
.box_cat_rooms .info h3 {
    color: #fff;
    font-size: 26px;
    font-weight: 600;
    margin-bottom: 5px;
}
.box_cat_rooms .info small {
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: 1px;
    display: block;
    margin-bottom: 0;
    font-size: 0.75rem;
    color: #fff;
}
h4.project-typology {
    color: #fff;
    font-size: 16px;
    padding-top: 10px;
    letter-spacing: 1px;
    font-weight: 500;
}
h4.project-price {
    color: #fff;
    font-size: 20px;
    letter-spacing: 1px;
    padding-bottom: 30px;
}
.query-btn-section {
    display: flex;
    justify-content: space-around;
    justify-content: space-evenly;
}
.query-btn-section .query-btn {
    min-width: 162px;
    background: #efefef1c;
    /* backdrop-filter: blur(10px); */
    border: 1px solid #fff;
    border-radius: 40px;
    color: #fff;
    text-align: center;
    outline: none;
    cursor: pointer;
    margin-top: 10px;
    display: inline-flex;
    font-weight: 600;
    transition: 0.3s ease-in-out;
    align-items: center;
    justify-content: center;
    line-height: 1;
}
.query-btn-section .query-btn {
    min-width: 162px;
    background: #efefef1c;
    /* backdrop-filter: blur(10px); */
    border: 1px solid #fff;
    border-radius: 40px;
    color: #fff;
    text-align: center;
    outline: none;
    cursor: pointer;
    margin-top: 10px;
    display: inline-flex;
    font-weight: 600;
    transition: 0.3s ease-in-out;
    align-items: center;
    justify-content: center;
    line-height: 1;
    padding: 13px 25px;
}
.box_cat_rooms:hover .info {
    -webkit-transform: translate(0, -65px);
    -moz-transform: translate(0, -65px);
    -ms-transform: translate(0, -65px);
    -o-transform: translate(0, -65px);
    transform: translate(0, 10px);
    bottom: -80px;
}