img {
  width: 100%;
}
.btn {
  background: #be9040;
  color: #fff;
  outline: none;
  border: none;
}
.btn:hover {
  background: #be9040;
  color: #fff;
  outline: none;
  border: none;
}
.prop-title-name {
  color: #303030;
  font-size: 18px;
  font-weight: 600;
  line-height: 20px;
  padding-bottom: 4px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.prop-loc,
.prop-size {
  color: #606060;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.prop-type {
  color: #303030;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  padding-bottom: 12px;
}
.prop-box {
  padding: 20px 16px;
  position: relative;
}
.Feathers-items img {
  width: 100%;
  height: 200px;
  -o-object-fit: cover;
  object-fit: cover;
  transition: all 0.35s ease-in;
}
.prop-main {
  box-shadow: 0 1px 4px 0 rgba(0, 106, 194, 0.2);
  border: none;
  border-radius: 5px;
  overflow: hidden;
  background: #fff;
}

.banner-box img {
  object-fit: cover;
  height: 400px;
  width: 100%;
}

.banner-box {
  position: relative;
}
.banner-box::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  height: 100%;
  background-image: linear-gradient(90deg, #074386 0%, #68e1f1 100%);
  opacity: 0.3;
}
.banner-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.banner-text h3 {
  font-size: 7em;
  color: #fff;
}
.main-box-padd {
  padding: 100px 0px;
}
.main-box-padd .heading-text {
  font-size: 30px;
  font-weight: 600;
}
.Feathers-items {
  position: relative;
}
.main-box {
  padding: 120px 0px;
}
.titile--main {
  font-size: 2.5rem;
  font-weight: 700;
}
.divider-traingle {
  width: 200px;
  border: 1px solid #be9040;
  opacity: 0.4;
  position: relative;
  display: inline-block;
  text-align: center;
}
.divider-traingle:before {
  background-color: #be9040;
  right: 50%;
}

.divider-traingle:after,
.divider-traingle:before {
  width: 18px;
  height: 18px;
  top: -9px;
  opacity: 0.4;
  transform: rotate(45deg);
  position: absolute;
  content: "";
}
.divider-traingle:after {
  background: #be9040;
  left: 45%;
}
.divider-traingle:after,
.divider-traingle:before {
  width: 18px;
  height: 18px;
  top: -9px;
  opacity: 0.4;
  transform: rotate(45deg);
  position: absolute;
  content: "";
}
.PhotonCard--gallery .PhotonCard--img1,
.map img {
  height: 465px;
  object-fit: cover;
  width: 100%;
  border-radius: 10px 0px 0px 10px;
  overflow: hidden;
}
.left--gallery,
.right--gallery {
  overflow: hidden;
}
.right--gallery img {
  overflow: hidden;
  border-radius: 0px 10px 10px 0px;
}
.PhotonCard--gallery .PhotonCard--img2 {
  height: 210px;
  object-fit: cover;
  width: 100%;
  border-radius: 6px;
  margin-bottom: 10px;
}
.main--boxprop img {
  width: 150px;
  height: 65px;
  object-fit: cover;
  margin-right: 10px;
  border-radius: 4px;
}
.main--boxprop {
  display: flex;
}
.leftpropbox h4 {
  margin-bottom: 0px;
}
img.prop-banner-img {
  height: 460px;
  border-radius: 6px;
  object-fit: cover;
}

.modal .left_col,
.modal .right_col {
  max-width: 50%;
  flex: 0 0 50%;
  padding: 30px;
  justify-content: center;
}

.modal .left_col {
  background-color: #ebe5db;
  display: flex;
  align-items: center;
}
.modal-body {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}
.modal .btn-close {
  position: absolute;
  color: #fff !important;
  opacity: 1;
  text-shadow: none;
  font-size: 40px;
  top: 10px;
  right: 20px;
  z-index: 9;
}
.modal .right_col .form-input {
  height: 46px;
}
.animated_form .form-input {
  position: relative;
  padding: 5px 10px;
  width: 100%;
  height: 50px;
  outline: 0;
  border: 0;
  box-shadow: 0 1px 0 0 #e5e5e5;
  transition: box-shadow 150ms ease-out;
  border-radius: 5px;
}
.animated_form .form-group {
  margin-bottom: 20px;
}
.animated_form input.btn {
  background-color: #144b8e;
  height: 50px;
  font-size: 16px;
  font-weight: 500;
  color: #fff;
  border-radius: 5px;
}
.modal .left_col .typo {
  font-size: 18px;
  margin-bottom: 10px;
  text-align: center;
  font-weight: 400;
  line-height: 1.2;
}
.modal .left_col img {
  margin-bottom: 15px;
}
.modal .left_col .price {
  font-size: 20px;
  background-color: #ffdc73;
  padding: 7px 15px;
  width: 100%;
  display: inline-block;
  font-weight: 600;
  color: #333;
}
.modal-body {
  padding: 0px;
}

.list_header_bold {
  font-size: 16px;
  line-height: 20px;
  font-weight: 700;
  color: #091e42;
  display: block;
  text-align: left;
  vertical-align: middle;
  margin-bottom: 20px;
}
.prop-headertext {
  display: flex;
  align-items: center;
  margin-bottom: 25px;
}
.prop-headertext i {
  margin-right: 10px;
  font-size: 25px;
}
.badges_large_secondary {
  font-weight: 600;
  color: #42526e;
  display: block;
  text-align: left;
  vertical-align: middle;
  font-size: 16px !important;
  line-height: 20px !important;
  display: flex !important;
}
.caption_subdued_medium {
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
  color: #8993a4;
  display: block;
  text-align: left;
  vertical-align: middle;
  color: #42526e !important;

  margin-bottom: 2px;
}
.FloorPlanCardV2__fpReqCallBack {
  display: inline-flex;
  cursor: pointer;
  bottom: 1px;
  padding: 5px 15px;
  justify-content: space-between;
  background: #f1b348e0;
  border-radius: 5px;
}
.FloorPlanCardV2__fpReqCallBack i {
  color: #fff;
}
.buttonText_medium {
  font-size: 14px;
  line-height: 20px;
  font-weight: 700;
  color: #fff;
  display: block;
  text-align: left;
  vertical-align: middle;
}
.amenities-box img {
  width: 150px;
  height: 150px;
  border-radius: 8px;
  margin-bottom: 30px;
  object-fit: cover;
}
.amenities-boxs img {
  width: 50px;
  height: 50px;
  border-radius: 8px;
  margin-bottom: 30px;
  object-fit: cover;
}
.amenities-box,
.amenities-boxs {
  padding: 20px;
  background: #fff;
  border-radius: 8px;
}

.amenities-box h4,
.amenities-boxs h4 {
  font-size: 18px;
  font-weight: 500;
  color: #be9040;
}
.location-box ul {
  padding-left: 0px;
}
.location-box li {
  position: relative;
  margin-bottom: 8px;
}
.location-box li span {
  height: 30px;
  width: 30px;
  border-radius: 5px;
  background-color: #144b8e;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  font-weight: 500;
  font-size: 18px;
  color: #fff;
}
.gallery-box img {
  height: 280px;
  object-fit: cover;
  border-radius: 8px;
}
.contact-from {
  background: #c8e8e9;
  display: flex;
  align-items: center;
  justify-content: center;
}
.contact-form {
  background: #fff;
  border-radius: 6px;
  padding: 20px 60px 30px 40px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
}
.contact-form .content {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.contact-form .content .left-side {
  width: 25%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 15px;
  position: relative;
}
.content .left-side::before {
  content: "";
  position: absolute;
  height: 70%;
  width: 2px;
  right: -15px;
  top: 50%;
  transform: translateY(-50%);
  background: #afafb6;
}
.content .left-side .details {
  margin: 14px;
  text-align: center;
}
.content .left-side .details i {
  font-size: 30px;
  color: #be9040;
  margin-bottom: 10px;
}
.content .left-side .details .topic {
  font-size: 18px;
  font-weight: 500;
}
.content .left-side .details .text-one,
.content .left-side .details .text-two {
  font-size: 14px;
  color: #afafb6;
}
.contact-form .content .right-side {
  width: 75%;
  margin-left: 75px;
}
.content .right-side .topic-text {
  font-size: 23px;
  font-weight: 600;
  color: #be9040;
}
.right-side .input-box {
  height: 50px;
  width: 100%;
  margin: 12px 0;
}
.right-side .input-box input,
.right-side .input-box textarea {
  height: 100%;
  width: 100%;
  border: none;
  outline: none;
  font-size: 16px;
  background: #f0f1f8;
  border-radius: 6px;
  padding: 0 15px;
  resize: none;
}
.right-side .message-box {
  min-height: 110px;
}
.right-side .input-box textarea {
  padding-top: 6px;
}
.right-side .button {
  display: inline-block;
  margin-top: 12px;
}
.right-side .button input[type="button"] {
  color: #fff;
  font-size: 18px;
  outline: none;
  border: none;
  padding: 8px 16px;
  border-radius: 6px;
  background: #be9040;
  cursor: pointer;
  transition: all 0.3s ease;
}
.button input[type="button"]:hover {
  background: #be9040;
}
/* propertes Pages */
.box--project img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 8px;
  margin-bottom: 10px;
}
.box--project .img--box::before {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #00000052;
  z-index: 9;
  border-radius: 8px;
}
.box--project .img--box {
  position: relative;
}
.sticky {
  position: fixed;
  top: 0;
  width: 100%;
  
}
.box--project span {
  position: absolute;
  right: 30px;
  top: 30px;
  color: #fff;
  z-index: 10;
  background: #00000075;
  padding: 5px;
  border-radius: 22px;
  font-size: 13px;
}

.box--project h3 {
  font-size: 20px;
  margin-top: 15px;
}
.box--project p {
  text-align: justify;
  font-size: 15px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}
.projectbanner img {
  border-radius: 8px;
  margin-bottom: 30px;
}

.panel-title {
  color: #222;
  font-size: 16px;
  line-height: 20px;
  font-weight: 600;
}
.tablepress tfoot th,
.tablepress thead th {
  background-color: #efdcff !important;
}

.listing_detail {
  min-height: 32px;
  font-weight: 600;
  word-break: break-word;
  font-size: 14px;
  color: #4a4848;
}
.listing_detail strong {
  color: #878181;
  font-weight: 600;
  font-size: 15px;
}
.resBth {
  background-color: #efdcff !important;
  color: #545454 !important;
  background-color: #0dc3f8;
  padding: 1px 6px;
  border-radius: 0px;
  width: fit-content;
  clear: both;
  float: left;
  margin-top: 10px;
  webkit-transition: background-color 0.1s linear;
  -o-transition: background-color 0.1s linear;
  transition: background-color 0.1s linear;
  font-size: 13px;
  font-weight: 700;
  border-radius: 2px;
  line-height: 1.65em;
}
.feature_chapter_name {
  font-weight: 800;
  margin: 15px 0px 10px 0px;
  position: relative;
  color: #6a6767;
  float: left;
  font-size: 15px;
}

.checkon {
  color: #bd8be7 !important;
  margin-right: 10px;
  font-weight: 900;
  font-style: initial;
}
.gallery-boxsemmar.h-90 {
  height: 180px;
}
img.PhotonCard--img2.zoomout.mb-3 {
  height: 225px;
}
.coming-sson {
  line-height: 1.3em;
  text-align: center;
  font-size: 197px;
  letter-spacing: 5.2px;
  font-weight: 800;
  text-transform: uppercase;
  background-image: url("https://miro.medium.com/v2/resize:fit:1000/1*PWdG4Rs528yuQgIOY0b8-g.jpeg");
  background-size: 100% 100%;
  -webkit-background-clip: text;
  color: transparent;
}
.brands-container img {
  width: 200px;
  height: 200px;
  object-fit: contain;
  margin-bottom: 10px;
  display: block;
  margin: 20px auto;
}
.card {
  box-shadow: 1px 0 10px 2px rgba(0, 0, 0, 0.15);
  padding: 20px;
  border-radius: 5px;
}
.brands-container {
  text-align: center;
}
h4.brands--title {
  text-align: center;
  font-size: 22px;
  font-weight: 600;
}
.brands-container h6 {
  font-size: 15px;
  font-weight: 500;
}
.mycontct-formz {
  box-shadow: 0px 7px 30px -1px rgb(207 174 194 / 22%) !important;
  border: 0px;
  border: 1px solid #f1f8ff;
  position: relative;
  overflow: hidden;
  background-color: #fff;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.mycontct-form {
  margin-left: 30px;
  cursor: pointer;
  display: block;
  color: #333 !important;
  padding-left: 0px !important;
  font-size: 14px;
  font-weight: 600;
  line-height: 1.9em;
}
.iccon-form svg {
  color: #777;
  margin-right: 10px;
  background: #f2f2f2;
  position: relative;
  width: 30px;
  height: 30px;
  padding: 5px;
  display: inline-block;

  border-radius: 50%;
  margin-bottom: 10px;
}
.mycontct-form a {
  color: #333 !important;
}

.box-formcontact {
  display: flex;
  text-align: left;
  margin-bottom: 9px;
}
.contct-title {
  font-size: 20px;
  margin-bottom: 20px;
}
.inputfromtext input {
  box-sizing: border-box;
  padding: 0.72em 0.9em 0.65em;
  border-radius: 0px;
  border: 1px solid #d6d8d9;
  line-height: 1.4em;
  font-family: inherit;
  height: auto;
  box-shadow: none;
  -webkit-box-shadow: none;
  -webkit-appearance: none;
  font-size: inherit;
}
.elwidgetbox {
  position: absolute;
  bottom: 10px;
  left: 20px;
  color: #fff;
  text-align: justify;
}
.elwidgetbox h4 {
  margin-bottom: 0;
  font-size: 20px;
}
.gallery-boxsemmar {
  position: relative;
  border-radius: 5px;
  overflow: hidden;
}
.gallery-boxsemmar::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 75%;
  background: #000;
  background: linear-gradient(0deg, black 0%, rgba(255, 255, 255, 0) 100%);
}
.gallery-boxsemmar img {
  border-radius: 5px;
  height: 180px;
}
.gallery-boxsemmar.col-r img {
  border-radius: 5px;
  height: 390px;
}

.banned{
  clip-path: polygon(100% 27%, 100% 77%, 0 98%, 0 7%);
  border-radius: 8px;
}
.services-pannel-box {
  display: flex;
  align-items: center;
  background: #fff;
  padding: 20px;
  border-radius: 10px;
  position: relative;
  left: 70px;
  z-index: 100;
  width: 47em;
  top: 200px;
}
.services-pennal-listBoxs {
  position: relative;
    margin-right: 20px;
   
  
}
.services-pennal-listBoxs::before {
  content: "";
    position: absolute;
    right: -14px;
    top: 0;
    width: 2px;
    height: 100%;
    background: #cbcbcb;
    border-radius: 10px;
}

.head_line h4 {
  padding: 14px 30px;
  font-size: 18px;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  display: block;
  font-weight: 800;
  margin-left: 25px;
  margin-top: 20px;
  position: relative;
  left: 0;
  color: #121212;
  max-width: 400px;
  z-index: 1;
}
.head_line h4:before {
  position: absolute;
  content: "";
  height: 100%;
  width: 10px;
  background: #d7c589;
  right: calc(100% + 10px);
  top: 0;
  transform: skewX(-17deg);
}
.head_line h4:after {
  position: absolute;
  content: "";
  background: #d7c589;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  clip-path: polygon(15px 0, 100% 0, calc(100% - 15px) 100%, 0 100%);
}

.project--itemDetails {
  position: absolute;
  top: 20%;
  left: 10%;
}
.priivana-project {
  position: relative;
  border-radius: 8px;
  overflow: hidden;
}

.rupee_symbol {
  transform: translateY(-50%);
  backface-visibility: visible !important;
  animation: flip 2s ease infinite;
  position: absolute;
  top: 30%;
  left: 20px;
  transform: translateY(-50%);
  font-size: 88px;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #fff;
}
@keyframes flip {
  0% {
    transform: perspective(400px) rotateY(0) translateY(-50%);
    animation-timing-function: ease-out;
  }
  /* 70% {
      transform: perspective(400px) rotateY(300deg) translateY(-50%);
      animation-timing-function: ease-in;
  } */
  100% {
    transform: perspective(400px) rotateY(360deg) translateY(-50%);
    animation-timing-function: ease-in;
  }
}

/* animate end */
.st_price {
  margin: 50px 0;
  align-items: center;
  position: relative;
  line-height: initial;
  width: max-content;
  padding: 20px 90px;
  background-image: linear-gradient(
    to right,
    #000,
    rgb(0 0 0 / 40%),
    rgb(0 0 0 / 0%)
  );
}
.price_line {
  color: #fff;
  display: block;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 5px;
  text-transform: uppercase;
  position: relative;
}

.st_price .amount {
  font-size: 46px;
  font-weight: 600;
  letter-spacing: 1px;
  line-height: 45px;
  margin-top: -10px;
  display: block;
  position: relative;
  color: #d7c589;
}
.priivana-project::before {
  position: absolute;
  content: "";
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background: transparent;
  z-index: 0;
  background-image: linear-gradient(
    to right,
    #07332f,
    rgb(7 51 47 / 80%),
    rgb(7 51 47 / 40%),
    rgb(52 78 65 / 0%)
  );
}
.main-txt {
  font-size: 50px;
  margin: 10px 0 5px;
  font-weight: 600;
  color: #fff;
}

img.swiper-lazy.swiper-lazy-loaded {
  height: 210px;
  border-radius: 7px;
  object-fit: cover;
  padding: 4px;
}

.zoomin:hover .zoomout {
  transition: all 2.6s ease-in-out;
  transform: scale(1.1);
}
.zoomin {
  overflow: hidden;
  transition: all 2.6s ease-in-out;
  transform: scale(1);
}

@media (max-width: 950px) {
  .contact-form {
    width: 90%;
    padding: 30px 40px 40px 35px;
  }
  .contact-form .content .right-side {
    width: 75%;
    margin-left: 55px;
  }
}
@media (max-width: 820px) {
  .contact-form {
    margin: 40px 0;
    height: 100%;
  }
  .contact-form .content {
    flex-direction: column-reverse;
  }
  .contact-form .content .left-side {
    width: 100%;
    flex-direction: row;
    margin-top: 40px;
    justify-content: center;
    flex-wrap: wrap;
  }
  .contact-form .content .left-side::before {
    display: none;
  }
  .contact-form .content .right-side {
    width: 100%;
    margin-left: 0;
  }
  .banner-text h3 {
    font-size: 2.1em;
    color: #fff;
  }
  .coming-sson {
    font-size: 80px;
  }
}
